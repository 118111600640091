import Logo from '../images/logo-noir.png'
import ContactForm from './ContactForm'
import FooterLogo from '../images/circle-algego.png'

export default function Landing() {

    const Algego = 'https://algego.com'

    function BottomSection() {
        return (
            <>
                <div className='textOfBottomSection'>
                    <h3 className='bottomFirstLine'>Date et lieu</h3>
                    <div>Le mardi 11 octobre de 8h à 12h</div>
                    <div>Bureaux de ALGEGO</div>
                    <div>3970, rue Saint-Ambroise</div>
                    <div>Montréal H4C 2C7</div>
                    <div><a href='https://goo.gl/maps/5wcreragomfjfdXR7' target='_blank'>Lien Google Maps</a></div>

                    <div className='hourSection'>
                        <h3>Horaire de l'atelier</h3>
                        <div>8h00 à 8h15 &#62; Accueil</div>
                        <div>8h15 à 9h30 &#62; Mise en contexte et scénarisation</div>
                        <div>9h30 à 10h00 &#62; Visite du studio vert</div>
                        <div>10h00 à 12h00 &#62; Tournage en <em>motion capture</em></div>
                    </div>
                    <div className='hourSectionQuery'>
                        <h3>Horaire de l'atelier</h3>
                        <div>8h00 à 8h15</div><div className='itemQuery'>Accueil</div>
                        <div>8h15 à 9h30</div><div className='itemQuery'>Mise en contexte et scénarisation</div>
                        <div>9h30 à 10h00</div><div className='itemQuery'>Visite du studio vert</div>
                        <div>10h00 à 12h00</div><div className='itemQuery'>Tournage en <em>motion capture</em></div>
                    </div>

                    <h3>Information</h3>
                    <div>Julie Guillaume</div>
                    <div>Responsable du développement</div>
                    <div>et des solutions innovantes</div>
                    <div><a href='mailto:julieguillaume@algego.com'>julieguillaume@algego.com</a></div>
                </div>
            </>
        )
    }

    function MiddleSection() {
        return (
            <>
                <div className='middleTxt'>Vivez l'expérience immersive et ludique de la production</div>
                <div className='middleTxt'>d'une formation novatrice utilisant la technologie du <em>motion capture</em></div>
                <iframe src="https://player.vimeo.com/video/678893128?h=fd32598d78&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="800" height="455" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="Desjardins Assurances"></iframe>
                <div className='videoSub'>Teaser d'une formation avec <em>motion capture</em> (pour EnviroCompétences)</div>
            </>
        )
    }

    return (
        <>
            <div className='logo'>
                <div className='logoInner'><a href={Algego} target='_blank'><img src={Logo} alt='logo' /></a></div>
            </div>

            <div className='firstSection'>
                <div className='firstLine'>
                    <span className='firstLinePremier'>Devenez le héros de votre formation</span>
                    <span className='firstLineQuery'><div>Devenez le héros</div>de votre formation</span>
                </div>
                <div className='secondLine'>ATELIER GRATUIT</div>
            </div>

            <div className='secondSection'>
                <div className='secondFirst'>Le mardi 11 octobre 2022 de 8h à 12h</div>
                <div className='secondSecond'>Places limitées réservées aux CSMO - RSVP avant le 30 septembre à 17h</div>
            </div>

            <div className='container-fluid'>
                <div className='mainSection'>
                    <div className='row mainFirst'>
                        <div className='col-md-2 mainLeft'>
                        </div>
                        <div className='col-md-8 mainMiddle'>
                            <MiddleSection />
                        </div>
                        <div className='col-md-2 mainRight'>
                        </div>
                    </div>
                    <div className='row mainQuery'>
                        <div className='col-12 mainMiddle'>
                            <MiddleSection />
                        </div>
                    </div>
                </div>
                <div className='bottomSection'>
                    <div className='row bottomFirst'>
                        <div className='col-md-2'></div>
                        <div className='col-md-4'>
                            <BottomSection />
                        </div>
                        <div className='col-md-4'>
                            <ContactForm />
                        </div>
                        <div className='col-md-2'></div>
                    </div>
                    <div className='row bottomQuery'>
                        <div className='col-12'>
                            <BottomSection />
                        </div>
                        <div className='col-12'>
                            <ContactForm />
                        </div>
                    </div>
                </div>
                <footer>
                    <div className='footerTxt'><h3>De l'idée à la diffusion, ALGEGO a la solution.</h3></div>
                    <div className='footerTxtQuery'><h3><div>De l'idée à la diffusion</div>ALGEGO a la solution.</h3></div>
                    <div><a href={Algego} target='_blank'><img src={FooterLogo} alt='logo' /></a></div>
                </footer>
            </div>
        </>
    )
}