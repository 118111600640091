import './App.css';
import Landing from './components/Landing';

function App() {
  return (
    <>
      <Landing />
    </>
  );
}

export default App;
